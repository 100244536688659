import { clsMerge } from "@artifactlabs/shared-react-utils";
import { InputHTMLAttributes } from "react";
import { Tooltip } from "react-tooltip";

import { poppins } from "@/utils/font";

export const DisabledInput = ({
  label,
  withTooltip = false,
  tooltipContent,
  className,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  withTooltip?: boolean;
  tooltipContent?: string;
}) => {
  return (
    <div className={clsMerge(label && "min-h-[88px]")}>
      {label && (
        <div className="mt-4 flex gap-1">
          <p
            className={clsMerge(
              "pb-1 text-sm font-medium text-[#343434] antialiased",
              poppins.className,
            )}
          >
            {label}
          </p>
          {withTooltip && (
            <>
              <a data-tooltip-content={tooltipContent} data-tooltip-id={`tooltip-${label}`}>
                <svg fill="none" height="16" viewBox="0 0 16 16" width="16">
                  <path
                    d="M9.25687 8.35712L8.41897 8.89285C8.10304 9.11253 8.02067 9.30492 8.02067 9.67557V9.73052H6.26262V9.42847C6.26262 8.59057 6.44124 8.24733 7.3615 7.65665L8.32283 7.03843C8.85857 6.6952 9.25687 6.2969 9.25687 5.69268C9.25687 4.97822 8.78985 4.52497 7.9796 4.52497C7.15535 4.52497 6.68845 5.08812 6.68845 5.87107V6.26925H4.98535V5.77493C4.98535 4.12678 6.11154 3.04167 7.9796 3.04167C9.91604 3.04167 11.0149 4.03065 11.0149 5.69268C11.0149 6.96983 10.3968 7.65665 9.25687 8.35712ZM6.09747 12.9584V10.7745H8.18534V12.9584H6.09747ZM8 1C4.13402 1 1 4.13413 1 8C1 11.866 4.13402 15 8 15C11.866 15 15 11.866 15 8C15 4.13413 11.866 1 8 1Z"
                    fill="#343434"
                  />
                </svg>
              </a>
              <Tooltip
                className="max-w-[300px] rounded-md bg-white px-3 py-2 shadow-lg"
                id={`tooltip-${label}`}
                place="right"
                disableStyleInjection
              />
            </>
          )}
        </div>
      )}

      <input
        {...props}
        className={clsMerge(
          "h-10 w-full max-w-[456px] rounded-[4px] p-2 text-sm capitalize disabled:border-none disabled:bg-[#DCDCDC] disabled:text-[#343434]",
          className,
        )}
        disabled
      />
    </div>
  );
};
