//
import { Accordion } from "@szhsin/react-accordion";
import clsMerge from "clsx";
import React, { useMemo, useCallback } from "react";

//
import AccordionItem from "./AccordionItem";
import mapper from "./fieldMapper.json";
import { RequesterDetailState } from "./interface";
import ToggleButton from "./ToggleButton";

//
interface Props {
  selected: RequesterDetailState;
  setSelected: React.Dispatch<React.SetStateAction<RequesterDetailState>>;
  children?: React.ReactNode;
}

//
const RequesterDetails = ({ selected, setSelected, children }: Props) => {
  //
  const mappedKey = useMemo(() => mapper.requester_details, []);

  const totalCount = useMemo(() => Object.keys(selected).length, [selected]);

  const selectedCount = useMemo(
    () => Object.values(selected).filter(value => value).length,
    [selected],
  );

  /* ********************************************************************** */
  // Event Handler
  /* ********************************************************************** */

  //
  const handledOnToggle = useCallback(
    (key: string) => {
      setSelected((prevState: any) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    },
    [setSelected],
  );

  //
  const handleClearAll = useCallback(() => {
    setSelected((prevState: RequesterDetailState) => {
      const newState: RequesterDetailState = { ...prevState };
      (Object.keys(newState) as (keyof RequesterDetailState)[]).forEach(key => {
        newState[key] = false;
      });
      return newState;
    });
  }, [setSelected]);

  /* ********************************************************************** */
  // Renderer
  /* ********************************************************************** */

  //
  return (
    <Accordion className="w-full" transitionTimeout={200} transition>
      <AccordionItem
        selectedCount={selectedCount}
        showArrow={true}
        title="Requester detail"
        totalCount={totalCount}
        onClearAll={handleClearAll}
      >
        {children}
        <div className={clsMerge("mt-[16px] flex flex-wrap gap-x-[16px] gap-y-[16px]")}>
          <ToggleButton
            isSelected={selected.requester}
            label={`${mappedKey.requester}`}
            onToggle={() => handledOnToggle("requester")}
          />
          <ToggleButton
            isSelected={selected.entity_associated}
            label={`${mappedKey.entity_associated}`}
            onToggle={() => handledOnToggle("entity_associated")}
          />
          <ToggleButton
            isSelected={selected.email}
            label={`${mappedKey.email}`}
            onToggle={() => handledOnToggle("email")}
          />
          <ToggleButton
            isSelected={selected.phone_number}
            label={`${mappedKey.phone_number}`}
            onToggle={() => handledOnToggle("phone_number")}
          />
          <ToggleButton
            isSelected={selected.tier}
            label={`${mappedKey.tier}`}
            onToggle={() => handledOnToggle("tier")}
          />
          <ToggleButton
            isSelected={selected.title_designation}
            label={`${mappedKey.title_designation}`}
            onToggle={() => handledOnToggle("title_designation")}
          />
          <ToggleButton
            isSelected={selected.industry_of_the_entity}
            label={`${mappedKey.industry_of_the_entity}`}
            onToggle={() => handledOnToggle("industry_of_the_entity")}
          />
          <ToggleButton
            isSelected={selected.country_of_entity}
            label={`${mappedKey.country_of_entity}`}
            onToggle={() => handledOnToggle("country_of_entity")}
          />
          <ToggleButton
            isSelected={selected.entity_address}
            label={`${mappedKey.entity_address}`}
            onToggle={() => handledOnToggle("entity_address")}
          />
          <ToggleButton
            isSelected={selected.entity_phone_number}
            label={`${mappedKey.entity_phone_number}`}
            onToggle={() => handledOnToggle("entity_phone_number")}
          />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default RequesterDetails;
