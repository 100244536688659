//
import { Accordion } from "@szhsin/react-accordion";
import clsMerge from "clsx";
import React, { useMemo, useCallback } from "react";

//
import AccordionItem from "./AccordionItem";
import mapper from "./fieldMapper.json";
import { RequestDetailState } from "./interface";
import ToggleButton from "./ToggleButton";

//
interface Props {
  selected: RequestDetailState;
  setSelected: React.Dispatch<React.SetStateAction<RequestDetailState>>;
  children?: React.ReactNode;
}

//
const RequestDetails = ({ selected, setSelected, children }: Props) => {
  //
  const mappedKey = useMemo(() => mapper.request_details, []);

  const totalCount = useMemo(() => Object.keys(selected).length, [selected]);

  const selectedCount = useMemo(
    () => Object.values(selected).filter(value => value).length,
    [selected],
  );

  /* ********************************************************************** */
  // Event Handler
  /* ********************************************************************** */

  //
  const handledOnToggle = useCallback(
    (key: string) => {
      setSelected((prevState: any) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    },
    [setSelected],
  );

  //
  const handleClearAll = useCallback(() => {
    setSelected((prevState: RequestDetailState) => {
      const newState: RequestDetailState = { ...prevState };
      (Object.keys(newState) as (keyof RequestDetailState)[]).forEach(key => {
        newState[key] = false;
      });
      return newState;
    });
  }, [setSelected]);

  /* ********************************************************************** */
  // Renderer
  /* ********************************************************************** */

  //
  return (
    <Accordion className="w-full" transitionTimeout={200} transition>
      <AccordionItem
        selectedCount={selectedCount}
        showArrow={true}
        title="Requester detail"
        totalCount={totalCount}
        onClearAll={handleClearAll}
      >
        {children}
        <div className={clsMerge("mt-[16px] flex flex-wrap gap-x-[16px] gap-y-[16px]")}>
          <ToggleButton
            isSelected={selected.asset_title}
            label={`${mappedKey.asset_title}`}
            onToggle={() => handledOnToggle("asset_title")}
          />
          <ToggleButton
            isSelected={selected.use_type}
            label={`${mappedKey.use_type}`}
            onToggle={() => handledOnToggle("use_type")}
          />
          <ToggleButton
            isSelected={selected.license_type}
            label={`${mappedKey.license_type}`}
            onToggle={() => handledOnToggle("license_type")}
          />
          <ToggleButton
            isSelected={selected.territory_of_use}
            label={`${mappedKey.territory_of_use}`}
            onToggle={() => handledOnToggle("territory_of_use")}
          />
          <ToggleButton
            isSelected={selected.duration_of_use}
            label={`${mappedKey.duration_of_use}`}
            onToggle={() => handledOnToggle("duration_of_use")}
          />
          <ToggleButton
            isSelected={selected.image_resolution}
            label={`${mappedKey.image_resolution}`}
            onToggle={() => handledOnToggle("image_resolution")}
          />
          <ToggleButton
            isSelected={selected.accession_number}
            label={`${mappedKey.accession_number}`}
            onToggle={() => handledOnToggle("accession_number")}
          />
          <ToggleButton
            isSelected={selected.project_title_description}
            label={`${mappedKey.project_title_description}`}
            onToggle={() => handledOnToggle("project_title_description")}
          />
          <ToggleButton
            isSelected={selected.format_of_reproduction}
            label={`${mappedKey.format_of_reproduction}`}
            onToggle={() => handledOnToggle("format_of_reproduction")}
          />
          <ToggleButton
            isSelected={selected.language_of_use}
            label={`${mappedKey.language_of_use}`}
            onToggle={() => handledOnToggle("language_of_use")}
          />
          <ToggleButton
            isSelected={selected.publisher}
            label={`${mappedKey.publisher}`}
            onToggle={() => handledOnToggle("publisher")}
          />
          <ToggleButton
            isSelected={selected.use_of_image}
            label={`${mappedKey.use_of_image}`}
            onToggle={() => handledOnToggle("use_of_image")}
          />
          <ToggleButton
            isSelected={selected.print_run}
            label={`${mappedKey.print_run}`}
            onToggle={() => handledOnToggle("print_run")}
          />
          <ToggleButton
            isSelected={selected.retail_price}
            label={`${mappedKey.retail_price}`}
            onToggle={() => handledOnToggle("retail_price")}
          />
          <ToggleButton
            isSelected={selected.remarks}
            label={`${mappedKey.remarks}`}
            onToggle={() => handledOnToggle("remarks")}
          />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default RequestDetails;
