//
import clsMerge from "clsx";

import { poppins } from "@/utils/font";

interface Props {
  label: string;
  isSelected: boolean;
  onToggle: () => void;
}

//
const toggleButton = ({ label, isSelected, onToggle }: Props) => {
  //
  const buttonClass = clsMerge(
    poppins.className,
    "px-[12px] flex items-center justify-center h-[32px]",
    "rounded-[8px] border border-[#001246]",
    "text-[16px] leading-[24px] paragraph-[1px] font-[400]",
    isSelected ? "bg-[#001246] text-white" : "bg-white text-[#001246]",
  );

  return (
    <button
      className={buttonClass}
      key={label}
      onClick={() => {
        onToggle();
      }}
    >
      {label}
    </button>
  );
};

export default toggleButton;
