//
import { useMemo, useCallback } from "react";
import Select from "react-select";

import timezones from "./timezone.json";

//
interface TimezonePickerProps {
  onChange: (label: string) => void;
}

// Define custom styles
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "#EDECE5",
    border: "none",
    fontSize: "14px",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
  }),
  option: (provided: any) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
  }),
};

//
const TimezonePicker = (props: TimezonePickerProps) => {
  //
  const list = useMemo(() => {
    return timezones.map(timezone => ({
      value: timezone.text,
      label: timezone.text,
      offset: timezone.offset,
    }));
  }, []);

  //
  const handleChange = useCallback(
    (selectedOption: any) => {
      props.onChange(selectedOption.value);
    },
    [props],
  );

  //
  return (
    <Select
      isMulti={false}
      options={list}
      placeholder="Select timezone"
      styles={customStyles}
      onChange={handleChange}
    />
  );
};

export default TimezonePicker;
