//
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//
interface DateRangePickerProps {
  onSelect: (startDate: string | undefined, endDate: string | undefined) => void;
}

//
const DateRangePicker = (props: DateRangePickerProps) => {
  //
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  //
  const formatDate = (date: Date | undefined): string => {
    if (!date) return "";
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  //
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    if (start) {
      setStartDate(start || undefined);
    }

    if (end) {
      setEndDate(end || undefined);
    }

    //
    props.onSelect(
      formatDate(start ?? undefined) || undefined,
      formatDate(end ?? undefined) || undefined,
    );
  };

  //
  return (
    <div className="w-full">
      <DatePicker
        className="ml-[8px] w-full bg-[#EDECE5] text-[14px]"
        dropdownMode="select"
        endDate={endDate}
        placeholderText="dd/mm/yyyy - dd/mm/yyyy"
        showIcon={true}
        startDate={startDate}
        wrapperClassName="flex w-full bg-[#EDECE5]"
        scrollableYearDropdown
        selectsRange
        showMonthDropdown
        showYearDropdown
        useShortMonthInDropdown
        onChange={handleDateChange}
        onKeyDown={e => {
          e.preventDefault();
        }}
      />
    </div>
  );
};

export default DateRangePicker;
