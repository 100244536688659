import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { filter, omit, truncate } from "lodash";
import { DateTime } from "luxon";
import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { ChangeEvent, Fragment, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import RequestAdapter, { FormattedRequest } from "./RequestAdapter";

import AppButton from "@/components/AppButton";
import Container from "@/components/Layout/Container";
import MainLayout from "@/components/Layout/MainLayout";
import { ConfirmationModal } from "@/components/Modal";
import ExportModal from "@/components/Modal/Export";
import HorizontalTab from "@/components/Navigation/HorizontalTab";
import PageSelector from "@/components/Navigation/PageSelector";
import { RequestMetadata } from "@/components/Requests/RequestMetadata";
import Spinner from "@/components/Spinner";
import SquareCheckbox from "@/components/SquareCheckbox";
import {
  GetRequestByStateQuery,
  GetRequestByStateQueryVariables,
  LimitedUpdateRequestStatus,
  RequestStatus,
  RequestWithVersionInput,
  UpdateRequestsStatusMutation,
  UpdateRequestsStatusMutationVariables,
} from "@/gql/graphql";
import { NextPageWithLayout } from "@/pages/page";
import tenantAtom from "@/recoil/tenant";
import { poppins } from "@/utils/font";
import { getCurrencySymbols } from "@/utils/helpers/currencySymbols";

//
const getRequestByStateQuery = gql`
  query getRequestByState($status: String!, $options: RequestsQueryOptionsInput) {
    requests(status: $status, options: $options) {
      paginatedResults {
        extId
        version
        conditions {
          use
          territory
          usage
          duration
          resolution
        }
        details {
          title
          creator
          formatOfReproduction
          language
          useOfImage
          publisher
          printRun
          retailPrice
          remark
        }
        artwork {
          extId
          src
          metadata {
            name
            creationDate
            creatorName
            assetFileFormat
          }
          importRef {
            name
            value
          }
          assets {
            size
            width
            height
            dpi
            fileSize
          }
        }
        creatorProfile {
          firstName
          lastName
          entity {
            name
          }
          userTier
        }
        price
        createdAt
      }
      paginateMeta {
        totalCount
      }
    }
  }
`;

// TODO: add organization id for query
const getStatusFromLabel = (label?: string) => {
  if (!label) {
    return "";
  }
  if (label.toLowerCase() === "pending") {
    return RequestStatus.Pending;
  } else if (label.toLowerCase() === "approved") {
    return RequestStatus.Approved;
  } else if (label.toLowerCase() === "declined") {
    return RequestStatus.Rejected;
  } else if (label.toLowerCase() === "purchased") {
    return RequestStatus.Completed;
  } else {
    return "";
  }
};

const PAGE_SIZE = 1000;

const columnHelper = createColumnHelper<FormattedRequest>();

const updateRequestsStatus = gql`
  mutation updateRequestsStatus(
    $requests: [RequestWithVersionInput!]!
    $requestStatus: LimitedUpdateRequestStatus!
  ) {
    updateRequestsStatus(requestStatus: $requestStatus, requests: $requests) {
      success
    }
  }
`;

//
const Requests: NextPageWithLayout = () => {
  const searchParams = useSearchParams();
  const [rowSelection, setRowSelection] = useState<any>({});
  const [expandedRows, setExpandedRows] = useState({});
  const [isApprovedModalOpened, setIsApprovedModalOpened] = useState<boolean>(false);
  const [isRejectedModalOpened, setIsRejectedModalOpened] = useState<boolean>(false);
  const [isRevokedModalOpened, setIsRevokedModalOpened] = useState<boolean>(false);
  const [isExportModalOpened, setIsExportModalOpened] = useState<boolean>(false);

  const [requestWithVersion, setRequestWithVersion] = useState<RequestWithVersionInput>();

  const tenant = useRecoilValue(tenantAtom);

  const [tableData, setTableData] = useState<FormattedRequest[]>([]);

  const currency = tenant.settings.defaultCurrency;
  const currencySymbol = getCurrencySymbols(currency);

  const columns = useMemo(
    () =>
      (searchParams?.get("tab") !== "declined"
        ? [
            columnHelper.display({
              id: "select",
              header: ({ table }) => (
                <SquareCheckbox
                  className="pl-2"
                  id="select-all"
                  inputProps={{
                    checked: table.getIsAllRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              ),
              cell: ({ row }) => (
                <SquareCheckbox
                  className="mt-1 pl-2"
                  id={`select-row-${row.id}`}
                  inputProps={{
                    checked: row.getIsSelected(),
                    onClick: e => {
                      e.stopPropagation();
                    },
                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                      e.stopPropagation();
                      row.getToggleSelectedHandler()(e);
                    },
                  }}
                />
              ),
            }),
          ]
        : ([] as any)
      ).concat([
        columnHelper.accessor("requester", {
          cell: info => (
            <div className="flex items-center gap-4 px-4">
              <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-500">
                <span className="text-xs font-medium uppercase leading-none text-white">
                  {info.getValue().user.name.charAt(0)}
                </span>
              </span>
              <div>
                <div className="text-sm font-medium text-neutral-700">
                  {truncate(info.getValue().user.name)}
                </div>
                <div className="text-xs font-medium text-[#87888C]">
                  {truncate(info.getValue().user.entityName)}
                </div>
              </div>
            </div>
          ),
          header: () => <span className="pl-14">User</span>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor(row => row.tier, {
          id: "tier",
          cell: info => (
            <div
              className={clsMerge(
                "inline-flex h-[22px] flex-col items-center justify-center",
                "rounded-[100px] px-2 py-0.5",
                "text-xs font-medium text-white",
                info.getValue() === "ALL_ACCESS" ? "bg-[#8F211A]" : "bg-[#087E8B]",
              )}
            >
              {info.getValue() === "ALL_ACCESS" ? "ALL ACCESS" : info.getValue()}
            </div>
          ),
          header: () => <span>Tier</span>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor("price", {
          cell: info => (
            <div className="text-xs font-medium text-[#87888C]">
              {currencySymbol}
              {new Intl.NumberFormat("en-US").format(info.getValue())}
            </div>
          ),
          header: () => <span className="text-center">Price</span>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor("createdAt", {
          cell: info => (
            <div className="text-xs font-medium text-[#87888C]">
              {DateTime.fromMillis(Number(info.getValue())).toRelative() ?? ""}
            </div>
          ),
          header: searchParams?.get("tab") === "purchased" ? "Purchase date" : "Request date",
          footer: info => info.column.id,
        }),
        columnHelper.display({
          id: "actions",
          cell: props => (
            <div className="flex items-center justify-center gap-x-4">
              {searchParams?.get("tab") === "pending" && (
                <div className="flex flex-1 justify-center gap-4">
                  <button
                    className="h-[32px] w-[88px] rounded bg-[#1C2442] px-4 text-center text-sm font-medium leading-tight text-white"
                    onClick={e => {
                      e.stopPropagation();
                      setRequestWithVersion({
                        extId: props.row.original.id,
                        version: props.row.original.version,
                      });
                      setIsApprovedModalOpened(true);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="h-[32px] w-[88px] rounded px-4 text-center text-sm font-medium leading-tight text-[#1C2442] ring-1 ring-[#1C2442]"
                    onClick={e => {
                      e.stopPropagation();
                      setRequestWithVersion({
                        extId: props.row.original.id,
                        version: props.row.original.version,
                      });
                      setIsRejectedModalOpened(true);
                    }}
                  >
                    Decline
                  </button>
                </div>
              )}
              {searchParams?.get("tab") === "approved" && (
                <>
                  <button
                    className="h-[32px] w-[88px] rounded border border-[#1C2442] px-4 text-center text-sm font-medium leading-tight text-[#1C2442]"
                    onClick={e => {
                      e.stopPropagation();
                      setRequestWithVersion({
                        extId: props.row.original.id,
                        version: props.row.original.version,
                      });
                      setIsRevokedModalOpened(true);
                    }}
                  >
                    Revoke
                  </button>
                </>
              )}
            </div>
          ),
        }),
        columnHelper.display({
          id: "details",
          cell: props => (
            <button className="flex flex-1 items-center justify-center">
              {props.row.getIsExpanded() ? (
                <ChevronUpIcon className="h-5 w-8" />
              ) : (
                <ChevronDownIcon className="h-5 w-8" />
              )}
            </button>
          ),
        }),
      ]),
    [currencySymbol, searchParams],
  );

  const pageNumber = searchParams?.get("page") ? parseInt(searchParams?.get("page") as string) : 1;
  const status = getStatusFromLabel(searchParams?.get("tab")?.toString());

  const refetchParams = useMemo(() => {
    return { variables: { status, options: { limit: PAGE_SIZE, page: pageNumber, sort: 1 } } };
  }, [status, pageNumber]);

  const numSelection = useMemo(
    () => (rowSelection ? Object.values(rowSelection).length : 0),
    [rowSelection],
  );

  const [fetchRequests, { loading, data }] = useLazyQuery<
    GetRequestByStateQuery,
    GetRequestByStateQueryVariables
  >(getRequestByStateQuery, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchRequests(refetchParams);
  }, [fetchRequests, refetchParams]);

  const [updateRequestsStatusMutation] = useMutation<
    UpdateRequestsStatusMutation,
    UpdateRequestsStatusMutationVariables
  >(updateRequestsStatus);

  useEffect(() => {
    const refetchQuery = () => fetchRequests(refetchParams);
    if (pageNumber === 1) {
      window.addEventListener("focus", refetchQuery);
      return () => window.removeEventListener("focus", refetchQuery);
    }
  }, [fetchRequests, refetchParams, pageNumber]);

  useEffect(() => {
    setTableData(
      new RequestAdapter(data?.requests?.paginatedResults ?? [], tenant).getRequestList() || [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.requests?.paginatedResults]);

  const selectedRequestWithVersionInput = useMemo(() => {
    return filter(tableData, row => rowSelection[row.id] === true).map(row => {
      return {
        extId: row.id,
        version: row.version,
      };
    });
  }, [rowSelection, tableData]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      expanded: expandedRows,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: row => row.id,
    enableExpanding: true,
    onExpandedChange: setExpandedRows,
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
  });

  const postSubmitHandler = (submitExtIds: string[]) => {
    setRequestWithVersion(undefined);
    table.resetExpanded(true);
    table.setRowSelection(current => omit(current, submitExtIds));
    setTableData(current => current.filter(row => !submitExtIds.includes(row.id)));
    fetchRequests({ ...refetchParams, fetchPolicy: "network-only" });
  };

  useEffect(() => {
    table.resetRowSelection(true);
    table.resetExpanded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, searchParams?.get("tab")]);

  const requestsPlural = Object.keys(rowSelection)?.length > 1 ? "requests" : "request";

  const tabs = useMemo(
    () => [
      {
        name: "Pending",
        href: {
          pathname: "/requests",
          query: { tab: "pending" },
        },
        current: searchParams?.get("tab") === "pending",
      },
      {
        name: "Approved",
        href: {
          pathname: "/requests",
          query: { tab: "approved" },
        },
        current: searchParams?.get("tab") === "approved",
      },
      {
        name: "Declined",
        href: {
          pathname: "/requests",
          query: { tab: "declined" },
        },
        current: searchParams?.get("tab") === "declined",
      },
      {
        name: "Purchased",
        href: {
          pathname: "/requests",
          query: { tab: "purchased" },
        },
        current: searchParams?.get("tab") === "purchased",
      },
    ],
    [searchParams],
  );

  /* ************************************************************************************* */
  // Renderers
  /* ************************************************************************************* */

  //
  return (
    <Container
      className={clsMerge(
        "flex min-h-[calc(100vh+80px)] flex-col antialiased md:px-10",
        poppins.className,
      )}
    >
      <h1 className="font-poppins mt-[48px] w-[456px] text-3xl font-medium leading-normal tracking-wider text-black">
        REQUESTS
      </h1>

      {/*  */}
      <HorizontalTab className="font-poppins mt-10" tabs={tabs} />

      {/*  */}
      <div className="flex w-full pb-[38px] pt-10 text-[#343434] antialiased">
        <div className="flex w-[50%]">
          {(searchParams?.get("tab") === "approved" || searchParams?.get("tab") === "pending") && (
            <div className="font-poppins text-base font-normal">
              Items selected for request: {numSelection}
            </div>
          )}
        </div>
        <div className="flex w-[50%] items-end justify-end">
          <div className="font-poppins flex-col gap-4 text-xl font-medium tracking-wider text-[#343434] antialiased">
            <div className="flex w-full items-center justify-center gap-x-4">
              {/*  */}
              {searchParams?.get("tab") === "pending" && (
                <>
                  <AppButton
                    className="h-[32px] w-[138px] rounded bg-[#1C2442] px-4 text-center text-xs font-medium leading-tight text-white disabled:bg-[#B4B4B4] disabled:text-white"
                    disabled={numSelection === 0}
                    variant="contained"
                    onClick={() => {
                      setIsApprovedModalOpened(true);
                    }}
                  >
                    Approve selected
                  </AppButton>

                  <AppButton
                    className="h-[32px] w-[138px] rounded px-4 text-center text-xs font-medium leading-tight text-[#1C2442] ring-1 ring-[#1C2442] disabled:bg-transparent disabled:text-[#B4B4B4] disabled:ring-1 disabled:ring-[#B4B4B4]"
                    disabled={numSelection === 0}
                    variant="outlined"
                    onClick={() => {
                      setIsRejectedModalOpened(true);
                    }}
                  >
                    Decline selected
                  </AppButton>
                </>
              )}
              {/*  */}
              {searchParams?.get("tab") === "approved" && (
                <>
                  <AppButton
                    className="h-[32px] w-[138px] rounded px-4 text-center text-xs font-medium leading-tight text-[#1C2442] ring-1 ring-[#1C2442] disabled:bg-transparent disabled:text-[#B4B4B4] disabled:ring-1 disabled:ring-[#B4B4B4]"
                    disabled={numSelection === 0}
                    variant="outlined"
                    onClick={() => {
                      setIsRevokedModalOpened(true);
                    }}
                  >
                    Revoke selected
                  </AppButton>
                </>
              )}
              {/*  */}
              {searchParams?.get("tab") !== "pending" && (
                <>
                  <AppButton
                    className="h-[32px] w-[138px] rounded px-4 text-center text-xs font-medium leading-tight ring-1 ring-[#1C2442] disabled:bg-transparent disabled:text-[#B4B4B4] disabled:ring-1 disabled:ring-[#B4B4B4]"
                    variant="contained"
                    onClick={() => {
                      setIsExportModalOpened(true);
                    }}
                  >
                    Export
                  </AppButton>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      {loading ? (
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      ) : data?.requests?.paginateMeta?.totalCount === 0 ? (
        <div className="font-poppins mt-8 min-h-full flex-1 text-xs font-medium text-black antialiased">
          No records found.
        </div>
      ) : (
        <>
          <table className="mt-2 w-full border-separate border-spacing-x-0 border-spacing-y-4">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      align="left"
                      className={clsMerge(
                        poppins.className,
                        "text-xs font-medium leading-5 text-[#222]",
                      )}
                      colSpan={header.colSpan}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <Fragment key={row.id}>
                  <tr
                    className="h-[60px] flex-wrap bg-white"
                    onClick={e => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        className={clsMerge(
                          index == 0 && "rounded-bl-lg rounded-tl-lg",
                          index == row.getVisibleCells().length - 1 &&
                            "rounded-br-lg rounded-tr-lg",
                          poppins.className,
                        )}
                        key={cell.id}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr key={row.id + "expanded"}>
                      <td colSpan={row.getVisibleCells().length}>
                        <div className="rounded-bl-lg rounded-br-lg bg-white px-12 pb-12 pt-4 shadow-[0px_-20px_0px_0px_rgba(255,255,255,1)]">
                          <div className="flex">
                            <Image
                              alt={"img"}
                              className="object-fit max-h-40 w-40"
                              height={0}
                              src={row.original.artwork?.src}
                              width={160}
                            />
                            <div
                              className={clsMerge("flex w-full flex-col px-6", poppins.className)}
                            >
                              <p className={"font-medium italic"}>
                                {row.original.artwork?.metadata?.name}
                              </p>
                              <p className={"text-sm font-medium text-[#87888C]"}>
                                {row.original.artwork?.metadata?.creatorName}
                              </p>

                              <Link
                                className="flex justify-between py-4 text-sm text-[#0066CC] underline"
                                href={`/requests/${row.original.id}`}
                                prefetch={false}
                              >
                                View details
                              </Link>

                              <RequestMetadata row={row.original} />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </>
      )}

      {data?.requests?.paginatedResults?.length ? (
        <PageSelector
          currentPage={pageNumber}
          loading={loading}
          totalPages={Math.ceil((data?.requests?.paginateMeta?.totalCount || 0) / PAGE_SIZE)}
        />
      ) : (
        <div className="font-poppins mt-8 text-xs font-medium text-black antialiased">
          No record.
        </div>
      )}

      {/* ************************************************************************************* */}
      {/* Modals */}
      {/* ************************************************************************************* */}
      {/* Approval */}
      <ConfirmationModal
        description={`Do you want to approve selected ${requestsPlural}?`}
        handleClose={() => setIsApprovedModalOpened(false)}
        handleSubmit={async () => {
          if (selectedRequestWithVersionInput) {
            const requests = requestWithVersion
              ? [requestWithVersion]
              : selectedRequestWithVersionInput;
            await updateRequestsStatusMutation({
              variables: {
                requests,
                requestStatus: LimitedUpdateRequestStatus.Approved,
              },
            });
            setIsApprovedModalOpened(false);
            postSubmitHandler(requests.map(({ extId }) => extId));
          }
        }}
        isOpen={isApprovedModalOpened}
        key="request-approve-modal"
        submitButtonText="Confirm"
        title={`Approve ${requestsPlural}`}
      />

      {/* Reject */}
      <ConfirmationModal
        description={`Do you want to decline selected ${requestsPlural}?`}
        handleClose={() => setIsRejectedModalOpened(false)}
        handleSubmit={async () => {
          if (selectedRequestWithVersionInput) {
            const requests = requestWithVersion
              ? [requestWithVersion]
              : selectedRequestWithVersionInput;
            await updateRequestsStatusMutation({
              variables: {
                requests,
                requestStatus: LimitedUpdateRequestStatus.Rejected,
              },
            });
            setIsRejectedModalOpened(false);
            postSubmitHandler(requests.map(({ extId }) => extId));
          }
        }}
        isOpen={isRejectedModalOpened}
        key="request-rejected-modal"
        submitButtonText="Confirm"
        title={`Decline ${requestsPlural}`}
      />

      {/* Revoke */}
      <ConfirmationModal
        description={`Do you want to revoke selected ${requestsPlural}?`}
        handleClose={() => setIsRevokedModalOpened(false)}
        handleSubmit={async () => {
          if (selectedRequestWithVersionInput) {
            const requests = requestWithVersion
              ? [requestWithVersion]
              : selectedRequestWithVersionInput;
            await updateRequestsStatusMutation({
              variables: {
                requests,
                requestStatus: LimitedUpdateRequestStatus.Revoked,
              },
            });
            setIsRevokedModalOpened(false);
            postSubmitHandler(requests.map(({ extId }) => extId));
          }
        }}
        isOpen={isRevokedModalOpened}
        key="request-revoked-modal"
        submitButtonText="Confirm"
        title={`Revoke ${requestsPlural}`}
      />

      {/* Export */}
      <ExportModal
        handleClose={() => setIsExportModalOpened(false)}
        handleSubmit={async () => {
          setIsExportModalOpened(false);
        }}
        isOpen={isExportModalOpened}
        key="request-export-modal"
        submitButtonText="Export"
        title={`Export Requests`}
      />
    </Container>
  );
};

Requests.getLayout = (page: React.ReactNode) => {
  return <MainLayout>{page}</MainLayout>;
};

export default Requests;
