//
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccordionItem as Item } from "@szhsin/react-accordion";

//
interface props {
  showArrow: boolean;
  title: string;
  selectedCount: number;
  totalCount: number;
  onClearAll: () => void;
  children?: React.ReactNode;
}

//
const AccordionItem = ({
  showArrow,
  title,
  selectedCount,
  totalCount,
  onClearAll,
  ...rest
}: props) => {
  return (
    <Item
      {...rest}
      buttonProps={{
        className: `flex w-full items-center justify-center outline-none}`,
      }}
      className="items-center justify-center align-middle"
      contentProps={{
        className: "transition-height duration-200 ease-out",
      }}
      header={({ state: { isEnter } }) => (
        <div className="flex w-full items-center justify-between align-middle">
          <div className="flex w-full items-start justify-between">
            <div className="flex flex-col items-start text-sm font-[600]">
              <div>{title}</div>
              <div className="text-[12px] font-[400] text-[#87888C]">
                {selectedCount} of {totalCount} selected
              </div>
            </div>

            <div className="flex items-center gap-[16px]">
              <div
                className="text-sm text-[#4585FF]"
                role="button"
                onClick={e => {
                  e.stopPropagation();
                  onClearAll();
                }}
              >
                Clear all
              </div>

              {showArrow && (
                <FontAwesomeIcon
                  className={`transition-transform duration-200 ease-out ${isEnter && "rotate-180"}`}
                  height={14}
                  icon={faChevronDown}
                  width={14}
                />
              )}
            </div>
          </div>
        </div>
      )}
      // initialEntered
    />
  );
};

export default AccordionItem;
