/* eslint-disable @typescript-eslint/no-restricted-imports */
import { gql, useLazyQuery } from "@apollo/client";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { Dialog, Transition } from "@headlessui/react";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
import { Fragment, useState, useEffect, useMemo } from "react";

//
import mapper from "./fieldMapper.json";
import { TransactionDetailState, RequesterDetailState, RequestDetailState } from "./interface";
import RequestDetails from "./RequestDetails";
import RequesterDetails from "./RequesterDetails";
import TransactionDetails from "./TransactionDetails";

import DateRangePicker from "@/components/DateRangePicker";
import { useTypeDisplayKeyValueMap } from "@/components/Pages/Requests/RequestAdapter";
import { getDurationText } from "@/components/Requests/RequestDetails/RequestDetailsTab";
import Spinner from "@/components/Spinner";
import TimezonePicker from "@/components/TimezonePicker";
import { AdminGetTenantRequestsQuery, AdminGetTenantRequestsQueryVariables } from "@/gql/graphql";
import { poppins } from "@/utils/font";

//
interface Props {
  title: string;
  handleSubmit: () => void;
  handleClose: () => void;
  submitButtonText: string;
  isOpen: boolean;
}

//
const adminGetTenantRequestsQuery = gql`
  query adminGetTenantRequests($input: AdminGetTenantRequestsInput) {
    getTenantRequests(input: $input) {
      extId
      artwork {
        src
        metadata {
          name
        }
      }
      conditions {
        use
        territory
        usage
        duration
        resolution
      }
      details {
        title
        creator
        formatOfReproduction
        language
        useOfImage
        publisher
        printRun
        retailPrice
        remark
      }
      creatorProfile {
        firstName
        lastName
        entity {
          name
          jobTitle
          industry
          country
          addressLineOne
          addressLineTwo
          contactNumber
        }
        userTier
        contactNumber
      }
      user {
        auth {
          email
        }
      }
      currency
      price
      status
      createdAt
      updatedAt
    }
  }
`;

//
const ExportModal = ({ handleSubmit, handleClose, title, submitButtonText, isOpen }: Props) => {
  //
  const [isReady, setIsReady] = useState<boolean>(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  //
  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<string | undefined>(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>(undefined);

  //
  const mappedKey: any = useMemo(() => mapper, []);

  //
  const [selectedTransactionDetails, setSelectedTransactionDetails] =
    useState<TransactionDetailState>({
      id: true,
      price: true,
      status: true,
      requested_date: true,
      approved_date: false,
      declined_date: false,
      revoked_date: false,
    });

  const [selectedRequesterDetails, setSelectedRequesterDetails] = useState<RequesterDetailState>({
    requester: true,
    entity_associated: true,
    email: true,
    phone_number: false,
    tier: false,
    title_designation: false,
    industry_of_the_entity: false,
    country_of_entity: false,
    entity_address: false,
    entity_phone_number: false,
  });

  const [selectedRequestDetails, setSelectedRequestDetails] = useState<RequestDetailState>({
    asset_title: true,
    use_type: true,
    license_type: true,
    territory_of_use: true,
    duration_of_use: true,
    image_resolution: false,
    accession_number: false,
    project_title_description: false,
    format_of_reproduction: false,
    language_of_use: false,
    publisher: false,
    use_of_image: false,
    print_run: false,
    retail_price: false,
    remarks: false,
  });

  useEffect(() => {
    // setSubmitButtonDisabled(true);
    setIsReady(true);
  }, []);

  /* ************************************************************************************* */
  // Request
  /* ************************************************************************************* */

  const [fetchRequests] = useLazyQuery<
    AdminGetTenantRequestsQuery,
    AdminGetTenantRequestsQueryVariables
  >(adminGetTenantRequestsQuery, {
    fetchPolicy: "network-only",
  });

  /* ************************************************************************************* */
  // Event Handlers
  /* ************************************************************************************* */

  const handleOnExportConfirmButtonClick = async () => {
    // 1. Start Loading Dialog
    setLoading(true);

    // 2. Get request information from API
    const res = await fetchRequests({
      variables: {
        input: {
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        },
      },
    });

    // 3. Setup the fields for the export
    const workbook = new exceljs.Workbook(); // Create a new workbook
    const worksheet = workbook.addWorksheet("My Users"); // New Worksheet
    const columns = ["timezone", "date"];

    Object.keys(selectedTransactionDetails).forEach((key: string) => {
      if (selectedTransactionDetails[key as keyof TransactionDetailState]) {
        columns.push(key);
      }
    });

    Object.keys(selectedRequesterDetails).forEach((key: string) => {
      if (selectedRequesterDetails[key as keyof RequesterDetailState]) {
        columns.push(key);
      }
    });

    Object.keys(selectedRequestDetails).forEach((key: string) => {
      if (selectedRequestDetails[key as keyof RequestDetailState]) {
        columns.push(key);
      }
    });

    worksheet.columns = columns.map((key: any) => ({
      header:
        mappedKey.general[key] ??
        mappedKey.transaction_details[key] ??
        mappedKey.requester_details[key] ??
        mappedKey.request_details[key],
      key: key,
      width: 10,
    }));

    // worksheet.columns = [
    //   // General
    //   { header: "Timezone", key: "timezone", width: 10 },
    //   { header: "Date", key: "date", width: 10 },
    //   // Transaction Details
    //   { header: "Transaction ID", key: "id", width: 10 },
    //   { header: "Transaction Price", key: "price", width: 10 },
    //   { header: "Transaction Status", key: "status", width: 10 },
    //   { header: "Requested date", key: "requested_date", width: 10 },
    //   { header: "Approved date", key: "approved_date", width: 10 },
    //   { header: "Declined date", key: "declined_date", width: 10 },
    //   { header: "Revoked date", key: "revoked_date", width: 10 },
    //   // Requester Details
    //   { header: "Requester", key: "requester", width: 10 },
    //   { header: "Entity associated", key: "entity_associated", width: 10 },
    //   { header: "Email", key: "email", width: 10 },
    //   { header: "Phone number", key: "phone_number", width: 10 },
    //   { header: "Tier", key: "tier", width: 10 },
    //   { header: "Title/Designation", key: "title_designation", width: 10 },
    //   { header: "Industry of the entity", key: "industry_of_the_entity", width: 10 },
    //   { header: "Country of entity", key: "country_of_entity", width: 10 },
    //   { header: "Entity address", key: "entity_address", width: 10 },
    //   { header: "Entity phone number", key: "entity_phone_number", width: 10 },
    //   // Request Details
    //   { header: "Asset title", key: "asset_title", width: 10 },
    //   { header: "Use type", key: "use_type", width: 10 },
    //   { header: "License type", key: "license_type", width: 10 },
    //   { header: "Territory of use", key: "territory_of_use", width: 10 },
    //   { header: "Duration of use", key: "duration_of_use", width: 10 },
    //   { header: "Image resolution", key: "image_resolution", width: 10 },
    //   { header: "Accession number", key: "accession_number", width: 10 },
    //   { header: "Project title/description", key: "project_title_description", width: 10 },
    //   { header: "Format of reproduction", key: "format_of_reproduction", width: 10 },
    //   { header: "Language of use", key: "language_of_use", width: 10 },
    //   { header: "Publisher", key: "publisher", width: 10 },
    //   { header: "Use of image(s)", key: "use_of_image", width: 10 },
    //   { header: "Print run", key: "print_run", width: 10 },
    //   { header: "Retail price", key: "retail_price", width: 10 },
    //   { header: "Remarks", key: "remarks", width: 10 },
    // ];

    (res.data?.getTenantRequests ?? []).map((row: any) => {
      worksheet.addRow({
        // temp.push({
        timezone: selectedTimezone,
        date: formatField("date", row.updatedAt),

        id: selectedTransactionDetails.id ? formatField("string", row.extId) : "/",
        price: selectedTransactionDetails.price ? formatField("price", row.price) : "/",
        status: selectedTransactionDetails.status ? formatField("status", row.status) : "/",
        requested_date: selectedTransactionDetails.requested_date
          ? formatField("date", row.createdAt)
          : "/",
        approved_date: selectedTransactionDetails.approved_date
          ? formatField("approved_date", row.updatedAt, row.status)
          : "/",
        declined_date: selectedTransactionDetails.declined_date
          ? formatField("declined_date", row.updatedAt, row.status)
          : "/",
        revoked_date: selectedTransactionDetails.revoked_date
          ? formatField("revoked_date", row.updatedAt, row.status)
          : "/",

        requester: selectedRequesterDetails.requester
          ? formatField("string", `${row.creatorProfile.firstName} ${row.creatorProfile.lastName}`)
          : "/",
        entity_associated: selectedRequesterDetails.requester
          ? formatField("string", row.creatorProfile.name)
          : "/",
        email: selectedRequesterDetails.requester
          ? formatField("string", row.user.auth.email)
          : "/",
        phone_number: selectedRequesterDetails.requester
          ? formatField("string", row.creatorProfile.contactNumber)
          : "/",
        tier: selectedRequesterDetails.requester
          ? formatField("string", row.creatorProfile.userTier)
          : "/",
        title_designation: selectedRequesterDetails.requester
          ? formatField("string", row.creatorProfile.entity.jobTitle)
          : "/",
        industry_of_the_entity: selectedRequesterDetails.requester
          ? formatField("string", row.creatorProfile.entity.industry)
          : "/",
        country_of_entity: selectedRequesterDetails.requester
          ? formatField("string", row.creatorProfile.entity.country)
          : "/",
        entity_address: selectedRequesterDetails.requester
          ? formatField(
              "string",
              `${row.creatorProfile.entity.addressLineOne} ${row.creatorProfile.entity.addressLineTwo}`,
            )
          : "/",
        entity_phone_number: selectedRequesterDetails.requester
          ? formatField("string", row.creatorProfile.entity.contactNumber)
          : "/",

        asset_title: selectedRequestDetails.asset_title
          ? formatField("string", row.artwork.metadata.name)
          : "/",
        use_type: selectedRequestDetails.use_type
          ? formatField("use_type", row.conditions.use)
          : "/",
        license_type: selectedRequestDetails.license_type ? formatField("string", "/") : "/",
        territory_of_use: selectedRequestDetails.territory_of_use
          ? formatField("territory", row.conditions.territory)
          : "/",
        duration_of_use: selectedRequestDetails.duration_of_use
          ? formatField("duration", row.conditions.duration)
          : "/",
        image_resolution: selectedRequestDetails.image_resolution
          ? formatField("string", row.conditions.resolution)
          : "/",
        accession_number: selectedRequestDetails.accession_number
          ? formatField("string", "/")
          : "/",
        project_title_description: selectedRequestDetails.project_title_description
          ? formatField("string", row.details.title)
          : "/",
        format_of_reproduction: selectedRequestDetails.format_of_reproduction
          ? formatField("string", row.details.formatOfReproduction)
          : "/",
        language_of_use: selectedRequestDetails.language_of_use
          ? formatField("string", row.details.language)
          : "/",
        publisher: selectedRequestDetails.publisher
          ? formatField("string", row.details.publisher)
          : "/",
        use_of_image: selectedRequestDetails.use_of_image
          ? formatField("string", row.details.usage)
          : "/",
        print_run: selectedRequestDetails.print_run
          ? formatField("string", row.details.printRun)
          : "/",
        retail_price: selectedRequestDetails.retail_price
          ? formatField("price", row.details.retailPrice)
          : "/",
        remarks: selectedRequestDetails.remarks ? formatField("string", row.details.remark) : "/",
      });
    });

    //
    try {
      const buffer = await workbook.xlsx.writeBuffer(); // Generate the Excel file as a buffer
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `transaction-${selectedStartDate}-${selectedEndDate}.xlsx`); // Use FileSaver.js to save the file
    } catch (err) {
      console.error(err);
    }

    //
    setLoading(false);
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    handleSubmit();
  };

  /* ************************************************************************************* */
  //
  /* ************************************************************************************* */
  //
  const formatField = (key: string, value: string | number, other: string = "") => {
    //
    switch (key) {
      case "status": {
        if (value === "COMPLETED") {
          return "Purchased";
        } else if (value === "APPROVED") {
          return "Approved";
        } else if (value === "REJECTED") {
          return "Declined";
        }
        return "Revoked";
      }
      case "date": {
        return formatDate(value);
      }
      case "declined_date": {
        if (other === "REJETED") {
          return formatDate(value);
        }
        return "/";
      }
      case "revoked_date": {
        if (other === "REVOKED") {
          return formatDate(value);
        }
        return "/";
      }
      case "approved_date": {
        if (other === "COMPLETED" || other === "APPROVED") {
          return formatDate(value);
        }
        return "/";
      }
      case "price": {
        if (value) {
          return `HK$${value}`;
        }
        return "/";
      }
      case "duration": {
        return getDurationText(value as number);
      }
      case "use_type": {
        return useTypeDisplayKeyValueMap[value];
      }
      case "territory": {
        return value;
      }
      case "string":
      default: {
        if (value) {
          return value;
        }
        return "/";
      }
    }
  };

  //
  const formatDate = (value: string | number) => {
    const formattedDate = new Date(value).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  /* ************************************************************************************* */
  // Renderers
  /* ************************************************************************************* */

  //
  const renderDialogBody = () => {
    //
    return (
      <div className="flex flex-col items-start">
        {/* Timezone */}
        <div className="w-full border-b-[1px] py-[16px]">
          <div className="text-sm font-[600]">Timezone</div>
          <div className="mt-[16px] w-full">
            <TimezonePicker
              onChange={(text: string) => {
                setSelectedTimezone(text);
              }}
            />
          </div>
        </div>

        {/* Date range */}
        <div className="w-full border-b-[1px] py-[16px]">
          <div className="text-sm font-[600]">Date range</div>

          <div
            className={clsMerge(
              "mt-[16px] flex w-full",
              "rounded-[4px] border-[1px]",
              "text-[#87888C]",
            )}
          >
            <DateRangePicker
              onSelect={(startDate: string | undefined, endDate: string | undefined) => {
                setSelectedStartDate(startDate);
                setSelectedEndDate(endDate);
              }}
            />
          </div>
        </div>

        {/* Transaction details */}
        <div className="w-full border-b-[1px] py-[16px]">
          <TransactionDetails
            selected={selectedTransactionDetails}
            setSelected={setSelectedTransactionDetails}
          />
        </div>

        {/* Requester details */}
        <div className="w-full border-b-[1px] py-[16px]">
          <RequesterDetails
            selected={selectedRequesterDetails}
            setSelected={setSelectedRequesterDetails}
          />
        </div>

        {/* Request details */}
        <div className="w-full border-b-[1px] py-[16px]">
          <RequestDetails
            selected={selectedRequestDetails}
            setSelected={setSelectedRequestDetails}
          />
        </div>
      </div>
    );
  };

  //
  return (
    <>
      {!isReady && <></>}

      {isReady && (
        <Transition.Root as={Fragment} show={isOpen}>
          <Dialog className="absolute top-0 z-50 h-full w-full" onClose={() => undefined}>
            {/* Backdrop */}
            <Transition.Child
              as={"button"}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 z-40 bg-gradient-to-b from-[#1215244D] to-[#00000066] transition-opacity"
                role="button"
                onClick={handleClose}
              />
            </Transition.Child>

            {/*  */}
            <div className="fixed inset-0 z-50 m-auto flex w-full items-center justify-center">
              <Dialog.Panel
                className={clsMerge(
                  "bg-white",
                  "h-fit max-h-[80vh] min-h-[50vh] w-[456px] transform",
                  "text-left shadow-xl transition-all",
                  "rounded-lg px-[24px] pb-6 pt-10",
                  "overflow-y-auto overflow-x-hidden",
                )}
              >
                <div className="font-poppins antialiased">
                  <Dialog.Title
                    as="h3"
                    className={clsMerge("text-[24px] font-[600] text-[#343434]", poppins.className)}
                  >
                    {title}
                  </Dialog.Title>

                  {loading && (
                    <div className="flex items-center justify-center pt-[36px]">
                      <Spinner />
                    </div>
                  )}

                  {!loading && (
                    <>
                      <div className={clsMerge("mt-[36px]", poppins.className)}>
                        {renderDialogBody()}
                      </div>

                      {/* Action Buttons */}
                      <div
                        className={clsMerge(
                          "mt-10 flex items-center justify-center gap-x-10",
                          poppins.className,
                        )}
                      >
                        <button
                          className="inline-flex h-10 flex-col items-center justify-center rounded-[8px] border border-[#1C2442] px-4 text-[#1C2442] focus:outline-none"
                          type="button"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>

                        <button
                          className={clsMerge(
                            "outline:border-[#1C2442] inline-flex h-10 flex-col items-center justify-center rounded-[8px] border bg-[#1C2442] px-4 text-white",
                            submitButtonDisabled ? "cursor-not-allowed opacity-50" : "",
                          )}
                          disabled={submitButtonDisabled}
                          type="button"
                          onClick={handleOnExportConfirmButtonClick}
                        >
                          {submitButtonText}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default ExportModal;
