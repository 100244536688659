//
import { Accordion } from "@szhsin/react-accordion";
import clsMerge from "clsx";
import React, { useMemo, useCallback } from "react";

//
import AccordionItem from "./AccordionItem";
import mapper from "./fieldMapper.json";
import { TransactionDetailState } from "./interface";
import ToggleButton from "./ToggleButton";

//
interface Props {
  selected: TransactionDetailState;
  setSelected: React.Dispatch<React.SetStateAction<TransactionDetailState>>;
  children?: React.ReactNode;
}

//
const TransactionDetails = ({ selected, setSelected, children }: Props) => {
  //
  const mappedKey = useMemo(() => mapper.transaction_details, []);

  const totalCount = useMemo(() => Object.keys(selected).length, [selected]);

  const selectedCount = useMemo(
    () => Object.values(selected).filter(value => value).length,
    [selected],
  );

  /* ********************************************************************** */
  // Event Handler
  /* ********************************************************************** */

  //
  const handledOnToggle = useCallback(
    (key: string) => {
      setSelected((prevState: any) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    },
    [setSelected],
  );

  //
  const handleClearAll = useCallback(() => {
    setSelected((prevState: TransactionDetailState) => {
      const newState: TransactionDetailState = { ...prevState };
      (Object.keys(newState) as (keyof TransactionDetailState)[]).forEach(key => {
        newState[key] = false;
      });
      return newState;
    });
  }, [setSelected]);

  /* ********************************************************************** */
  // Renderer
  /* ********************************************************************** */

  //
  return (
    <Accordion className="w-full" transitionTimeout={200} transition>
      <AccordionItem
        selectedCount={selectedCount}
        showArrow={true}
        title="Transaction detail"
        totalCount={totalCount}
        onClearAll={handleClearAll}
      >
        {children}
        <div className={clsMerge("mt-[16px] flex flex-wrap gap-x-[16px] gap-y-[16px]")}>
          <ToggleButton
            isSelected={selected.id}
            label={`${mappedKey.id}`}
            onToggle={() => handledOnToggle("id")}
          />
          <ToggleButton
            isSelected={selected.price}
            label={`${mappedKey.price}`}
            onToggle={() => handledOnToggle("price")}
          />
          <ToggleButton
            isSelected={selected.status}
            label={`${mappedKey.status}`}
            onToggle={() => handledOnToggle("status")}
          />
          <ToggleButton
            isSelected={selected.requested_date}
            label={`${mappedKey.requested_date}`}
            onToggle={() => handledOnToggle("requested_date")}
          />
          <ToggleButton
            isSelected={selected.approved_date}
            label={`${mappedKey.approved_date}`}
            onToggle={() => handledOnToggle("approved_date")}
          />
          <ToggleButton
            isSelected={selected.declined_date}
            label={`${mappedKey.declined_date}`}
            onToggle={() => handledOnToggle("declined_date")}
          />
          <ToggleButton
            isSelected={selected.revoked_date}
            label={`${mappedKey.revoked_date}`}
            onToggle={() => handledOnToggle("revoked_date")}
          />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default TransactionDetails;
