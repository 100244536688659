import { useTypeToReadableMapping } from "@artifactlabs/shared-utils";
import { Duration } from "luxon";

import { DisabledInput } from "./DisabledInput";

import { Request } from "@/gql/graphql";

interface Props {
  request?: Request | null;
}

export const getDurationText = (duration: number) => {
  if (!duration) {
    return "";
  }

  if (duration === -1) {
    return "unlimited";
  }

  const durationObj = Duration.fromObject({ milliseconds: duration });
  const numYears = durationObj.as("years");
  const numMonths = durationObj.as("months");
  const numDays = durationObj.as("days");

  if (numYears >= 1) {
    return `${numYears} Year`;
  } else if (numMonths >= 1) {
    return `${numMonths} Month`;
  } else {
    return `${numDays} Day`;
  }
};

export const RequestDetailsTab = ({ request }: Props) => {
  return (
    <div className="mt-10 flex flex-col gap-4">
      <DisabledInput
        defaultValue={useTypeToReadableMapping[(request?.conditions?.use || "").toLowerCase()]}
        label="Use type"
      />
      <DisabledInput defaultValue={request?.conditions?.resolution} label="Image resolution" />
      <DisabledInput defaultValue={request?.conditions?.territory} label="Territory of use" />
      <DisabledInput defaultValue={request?.conditions?.usage} label="Usage" />
      <DisabledInput
        defaultValue={getDurationText(request?.conditions?.duration ?? 0)}
        label="Duration of use (year)"
      />
      <DisabledInput
        defaultValue={request?.details?.formatOfReproduction}
        label="Format of reproduction"
        tooltipContent="Description of the format of the project (e.g., digital advertisement, newsletters, physical prints, books)"
        withTooltip
      />

      <DisabledInput defaultValue={request?.details?.title} label="Project title / description" />
      <DisabledInput
        defaultValue={request?.details?.creator || "John Doe"}
        label="Project creator / author"
      />
      <DisabledInput defaultValue={request?.details?.language} label="Language of Use" />
      <DisabledInput
        defaultValue={request?.details?.useOfImage}
        label="Use of image(s)"
        tooltipContent="Description of how the image will be used as a part of the project (e.g., front-page cover, back page cover, full page, 1/2 page insert, digital banner)."
        withTooltip
      />
      <DisabledInput defaultValue={request?.details?.publisher} label="Publisher" />
      <DisabledInput defaultValue={request?.details?.printRun || ""} label="Print run" />
      <DisabledInput
        defaultValue={(request?.details?.retailPrice || 0).toFixed(2)}
        label="Retail price"
      />
      <DisabledInput defaultValue={request?.details?.remark || ""} label="Remarks" />
    </div>
  );
};
